import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>Software Engineer @<a href="https://vote.org">Vote.org</a></p>
    <ul class="social">
      <li><a href="https://github.com/mef79">GitHub</a></li>
      <li><a href="https://twitter.com/meaghan_tron">Twitter</a></li>
      <li><a href="https://www.linkedin.com/in/meaghan-fenelon-891a0a67/">LinkedIn</a></li>
    </ul>
    {/*<Link to="/page-2/">Go to page 2</Link>*/}
  </Layout>
)

export default IndexPage
